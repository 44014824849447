import { buttonScrollTo } from "./buttonScroll/buttonScrollTo";
import { scrolltoHash } from "./scrollHash/scrollHash";

const initScrollTo = () => {
  // Scroll function when loading a site with a #!# hash
  // #!# is a special hash transformed by angularJs
  window.addEventListener("DOMContentLoaded", function () {
    buttonScrollTo("data-cui-scroll");
    buttonScrollTo("scroll-to"); // fallback for legacy attribute

    setTimeout(() => {
      scrolltoHash();
    }, 1500);
  });
};

initScrollTo();

export { initScrollTo, buttonScrollTo, scrolltoHash };
