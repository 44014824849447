export const congstarScrollTo = (target: string, event?: Event) => {
  event && event.preventDefault();

  const headerSelector = document.getElementsByClassName("header");

  const targetLocation = document.getElementById(target);

  if (target.length > 0) {
    if (headerSelector.length > 0) {
      const header = headerSelector[0];
      const headerHeight = header.getBoundingClientRect().height;

      targetLocation &&
        (targetLocation.style.cssText = `scroll-margin-top: ${headerHeight}px; scroll-snap-margin-top: ${headerHeight}px`);
    }
    targetLocation?.scrollIntoView({ block: "start", behavior: "smooth" });
  }
};
