import { congstarScrollTo } from "../util/congstarScrollTo";

export const scrolltoHash = () => {
  const hash = window.location.hash;

  if (hash) {
    const targetName =
      hash.length > 0 && hash.startsWith("#!#") ? hash.slice(3) : hash.slice(1);
    congstarScrollTo(targetName);
  }
};
